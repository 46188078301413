<template>
  <div class="text-container">
    <p>
      Wir unterstützen Sie bei der Analyse Ihrer betriebswirtschaftlichen
      Auswertungen. Die richtigen Erkenntnisse aus den vorliegenden Zahlen
      zeigen uns den Weg für den Erfolg Ihrer Apotheke.
    </p>
    <ul>
      <li>
        Ständiger Vergleich von Aufwand und Ertrag. Sind Umsatz und Rohertrag,
        sowie Liquidität „in Line“
      </li>
      <li>
        Soll/Ist-Vergleich um Missstände frühzeitig zu erkennen und daraus
        entsprechende Schlüsse ziehen.
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  metaInfo: {
    meta: [
      {
        name: "description",
        content:
          "Wir unterstützen Sie bei der Analyse der betriebswirtschaftlichen Auswertungen Ihrer Apotheke.",
      },
    ],
  },
};
</script>